var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c("v-icon", { attrs: { color: "error" } }, [_vm._v("mdi-alert")]),
          _vm._v(
            " " +
              _vm._s(_vm.details.error_file) +
              "に空欄または不正な値の入力が見つかりました "
          ),
        ],
        1
      ),
      [
        "error-remains",
        "error-stocks",
        "error-shipments",
        "error-customers",
      ].includes(_vm.mode)
        ? _c(
            "v-card-text",
            [
              _c("v-data-table", {
                attrs: { headers: _vm.tableHeaders, items: _vm.tableItems },
              }),
            ],
            1
          )
        : _vm.mode == "error-products"
        ? _c(
            "v-card-text",
            [
              _c("v-data-table", {
                attrs: { headers: _vm.tableHeaders, items: _vm.tableItems },
                scopedSlots: _vm._u([
                  {
                    key: "item.forecast_period",
                    fn: function ({ item }) {
                      return [
                        item.alerts.includes("invalid_fp")
                          ? _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(item.forecast_period)),
                            ])
                          : _c("span", [_vm._v(_vm._s(item.forecast_period))]),
                      ]
                    },
                  },
                  {
                    key: "item.service_rate",
                    fn: function ({ item }) {
                      return [
                        item.alerts.includes("invalid_sr")
                          ? _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(item.service_rate)),
                            ])
                          : _c("span", [_vm._v(_vm._s(item.service_rate))]),
                      ]
                    },
                  },
                  {
                    key: "item.price",
                    fn: function ({ item }) {
                      return [
                        item.alerts.includes("price_empty")
                          ? _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(item.price)),
                            ])
                          : _c("span", [_vm._v(_vm._s(item.price))]),
                      ]
                    },
                  },
                  {
                    key: "item.case_quantity",
                    fn: function ({ item }) {
                      return [
                        item.alerts.includes("quantities_null")
                          ? _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(item.case_quantity)),
                            ])
                          : _c("span", [_vm._v(_vm._s(item.case_quantity))]),
                      ]
                    },
                  },
                  {
                    key: "item.box_quantity",
                    fn: function ({ item }) {
                      return [
                        item.alerts.includes("quantities_null")
                          ? _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(item.box_quantity)),
                            ])
                          : _c("span", [_vm._v(_vm._s(item.box_quantity))]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "grey darken-2" },
              on: {
                click: function ($event) {
                  return _vm.hide()
                },
              },
            },
            [_vm._v("閉じる")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }