var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c("v-icon", { attrs: { color: "error" } }, [_vm._v("mdi-alert")]),
          _vm._v(" マスタにないコードを含むデータが見つかりました "),
        ],
        1
      ),
      _c(
        "v-card-text",
        _vm._l(Object.keys(_vm.details), function (key) {
          return _c("div", { key: `unknown-codes-${key}` }, [
            _vm.details[key].length > 0
              ? _c(
                  "div",
                  [
                    _vm._v(" " + _vm._s(_vm.subtitles[key]) + "： "),
                    _c("v-textarea", {
                      attrs: {
                        readonly: "",
                        rows: Math.min(_vm.details[key].length, 5),
                        value: _vm.details[key].join("\n"),
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        }),
        0
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "grey darken-2" },
              on: {
                click: function ($event) {
                  return _vm.hide()
                },
              },
            },
            [_vm._v("閉じる")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }