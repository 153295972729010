var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pa-3 ma-10", attrs: { tile: "" } },
    [
      _c(
        "v-overlay",
        {
          attrs: {
            color: "white",
            opacity: 0.6,
            absolute: "",
            value: _vm.overlay,
          },
        },
        [
          _c("v-progress-circular", {
            attrs: { color: "grey", indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
      _c("v-card-title", [_vm._v(" 需要予測を行う ")]),
      _c("v-card-text"),
      _c(
        "v-stepper",
        {
          attrs: { vertical: "", flat: "" },
          model: {
            value: _vm.currentStep,
            callback: function ($$v) {
              _vm.currentStep = $$v
            },
            expression: "currentStep",
          },
        },
        [
          _c(
            "v-stepper-step",
            { attrs: { step: "1", complete: _vm.currentStep > 1 } },
            [
              _c(
                "div",
                [
                  _vm._v(" 予測用データをアップロード "),
                  _vm.currentStep > 1
                    ? _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.goToFirstUpload()
                            },
                          },
                        },
                        [_vm._v(" 最初へ戻る ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c("first-upload-stepper-content", {
            attrs: { duct: _vm.duct, step: "1" },
            on: {
              complete: function ($event) {
                _vm.currentStep++
              },
            },
          }),
          _c(
            "v-stepper-step",
            { attrs: { step: "2", complete: _vm.currentStep > 2 } },
            [_vm._v(" 需要予測処理 ")]
          ),
          _c("forecast-stepper-content", {
            attrs: { step: "2", status: _vm.status },
            on: {
              complete: function ($event) {
                _vm.currentStep++
              },
            },
          }),
          _c(
            "v-stepper-step",
            { attrs: { step: "3", complete: _vm.currentStep > 3 } },
            [_vm._v(" 発注量計算用データをアップロード ")]
          ),
          _c("second-upload-stepper-content", {
            attrs: { duct: _vm.duct, step: "3" },
            on: {
              complete: function ($event) {
                _vm.currentStep++
              },
            },
          }),
          _c(
            "v-stepper-step",
            { attrs: { step: "4", complete: _vm.currentStep > 4 } },
            [_vm._v(" 発注テーブルを参照 ")]
          ),
          _c("v-stepper-content", { attrs: { step: "4" } }, [
            _vm.duct.STATUS && _vm.status == _vm.duct.STATUS.IN_POSTPROCESS
              ? _c(
                  "div",
                  [
                    _vm._v(" データ作成中... "),
                    _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "primary" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.duct.STATUS &&
            _vm.status == _vm.duct.STATUS.FINISHED_POSTPROCESS
              ? _c(
                  "div",
                  [
                    _vm._v(" データ作成が完了しました。"),
                    _c("br"),
                    _c("br"),
                    _c(
                      "v-btn",
                      { attrs: { color: "primary", to: "/main/order/view" } },
                      [_vm._v("発注テーブルを確認")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }