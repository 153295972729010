var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "700" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _vm.mode == "error-unknown-codes"
        ? _c("unknown-codes-error-card", {
            attrs: { details: _vm.details },
            on: { hide: _vm.hide },
          })
        : _c("csv-error-card", {
            attrs: { details: _vm.details, mode: _vm.mode },
            on: { hide: _vm.hide },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }