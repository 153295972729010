var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c("v-icon", { attrs: { color: "success" } }, [
            _vm._v("mdi-check-circle"),
          ]),
          _vm._v("データアップロードが完了しました "),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _vm._v(" 以下のようなデータが含まれています："),
          _c("br"),
          _c(
            "v-simple-table",
            { staticClass: "mt-2 mb-4", attrs: { dense: "" } },
            [
              _c("tbody", [
                _c("tr", [
                  _c("th", { staticStyle: { width: "25%" } }, [
                    _vm._v("実績データ週"),
                  ]),
                  _vm.shippedYW == ""
                    ? _c(
                        "td",
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "warning" } },
                            [_vm._v("mdi-alert")]
                          ),
                          _vm._v(" "),
                          _c("b", [_vm._v("データが見つかりません")]),
                        ],
                        1
                      )
                    : _c("td", [_vm._v(_vm._s(_vm.shippedYW))]),
                ]),
                _c("tr", [
                  _c("th", { staticStyle: { width: "25%" } }, [
                    _vm._v("引当データ週"),
                  ]),
                  _vm.orderYW == ""
                    ? _c(
                        "td",
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "warning" } },
                            [_vm._v("mdi-alert")]
                          ),
                          _vm._v(" "),
                          _c("b", [_vm._v("データが見つかりません")]),
                        ],
                        1
                      )
                    : _c("td", [_vm._v(_vm._s(_vm.orderYW))]),
                ]),
                _c("tr", [
                  _c("th", { staticStyle: { width: "25%" } }, [
                    _vm._v("shipments.csv 行数"),
                  ]),
                  _c("td", [_vm._v(_vm._s(_vm.details["shipments_length"]))]),
                ]),
                _c("tr", [
                  _c("th", { staticStyle: { width: "25%" } }, [
                    _vm._v("products.csv 行数"),
                  ]),
                  _c("td", [_vm._v(_vm._s(_vm.details["products_length"]))]),
                ]),
                _c("tr", [
                  _c("th", { staticStyle: { width: "25%" } }, [
                    _vm._v("customers.csv 行数"),
                  ]),
                  _c("td", [_vm._v(_vm._s(_vm.details["customers_length"]))]),
                ]),
              ]),
            ]
          ),
          _vm._v(" 以下の週における予測を開始します："),
          _c("br"),
          _c(
            "v-simple-table",
            { staticClass: "mt-2 mb-4", attrs: { dense: "" } },
            [
              _c("tbody", [
                _c("tr", [
                  _c("th", [
                    _vm._v(
                      _vm._s(
                        _vm.details["forecast_yw"]
                          ? _vm.getWeekStr(_vm.details["forecast_yw"])
                          : ""
                      )
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.confirm()
                },
              },
            },
            [_vm._v("開始")]
          ),
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "grey darken-2" },
              on: {
                click: function ($event) {
                  return _vm.hide()
                },
              },
            },
            [_vm._v("キャンセル")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }