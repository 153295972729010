var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-stepper-content",
    { attrs: { step: _vm.step } },
    [
      _vm._v(" 予測処理が完了するまでしばらくお待ち下さい "),
      _c(
        "v-card",
        {
          staticClass: "mx-auto text-center mt-10",
          attrs: { flat: "", width: "800" },
        },
        [
          _c("v-progress-linear", {
            attrs: { color: "primary", height: "15", value: _vm.progressValue },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ value }) {
                  return [
                    _c("strong", [_vm._v(_vm._s(Math.ceil(value)) + "%")]),
                  ]
                },
              },
            ]),
          }),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.statusList[_vm.status]) + " "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }